import type { StatusSystemType } from "@/types";

export const STATUS_SYSTEM_PRIZES: Record<string, number> = {
	bronze: 400,
	silver: 500,
	gold: 1500,
	platinum: 2000,
	sapphire: 3000,
	diamond: 4000,
	vip: 5000
};

export const STATUS_SYSTEM_COLOR_DICTIONARY: Partial<Record<StatusSystemType, string>> = {
	bronze: "--gent",
	silver: "--gusau",
	gold: "--guatire",
	platinum: "--geneve",
	sapphire: "--groningen",
	diamond: "--gulu",
	vip: "--gaomi"
};
